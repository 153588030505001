import { AlertTriangle, Truck } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Card, CardContent } from "../../../../@/components/ui/card";
import PrestaOrderById from "../../../../lib/PrestaOrderById";

function DepotOrderCard({ order, ordersDropoffs }) {
  const navigate = useNavigate();
  const handleOrderClick = () => {
    navigate(`/DepotOrderDetails/${order.idCommande}`, {
      state: { order, orderDropoff },
    });
  };
  //eslint-disable-next-line
  const [prestaOrderDetails, setPrestaOrderDetails] = useState({});
  useEffect(() => {
    PrestaOrderById(order.idCommande, setPrestaOrderDetails);
  }, [order]);

  const [orderDropoff, setOrderDropoff] = useState({});
  useEffect(() => {
    const orderDropoff = ordersDropoffs.find(
      (orderDropoff) => orderDropoff.orderID === order.idCommande
    );
    setOrderDropoff(orderDropoff);
  }, [order, ordersDropoffs]);

  return (
    <Card onClick={() => handleOrderClick()}>
      <CardContent className="p-2 h-fit hover:bg-slate-100 rounded-md cursor-pointer">
        <div className="flex flex-col gap-y-3">
          <div className="flex justify-between">
            <div className="flex-col">
              <p className="flex gap-x-2 items-center text-sm font-semibold">
                <Truck size={20} color="#008080" />
                {order.idFacture}-DO
              </p>
              <p className="text-sm font-semibold mt-3">
                {order?.user?.nom} {order?.user?.prenom}
              </p>
            </div>
            <div className="flex gap-2">
              {orderDropoff && orderDropoff.validation === false ? (
                <div className="flex text-xs font-bold text-red-500">
                  <AlertTriangle size={18} color="red" strokeWidth={3} />
                  Sac manquant
                </div>
              ) : null}
            </div>
            {/* <div className="flex justify-center items-center">
              <Backpack size={20} />{" "}
              <p className="text-sm font-bold mt-1 mx-1">
                {prestaOrderDetails.items && prestaOrderDetails.items.length}
              </p>
            </div> */}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default DepotOrderCard;
