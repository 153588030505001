import React, { useEffect, useState } from "react";
import { ArrowLeft, Check } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import NavigationButton from "./components/NavigationButton";
import OrdersDataService from "../../../services/orders.services";
import UsersDataService from "../../../services/users.services";
import TestDataService from "../../../services/test.services";
import DepotOrderCard from "./components/DepotOrderCard";

const DriverID = "LOXw3WgAf0XL9D6QdvWba92RNVF2";

function DepotDetails({ selectedDate }) {
  const { state } = useLocation();
  const { hub, shift, start } = state;
  const navigate = useNavigate();
  const [ordersWithDetails, setOrdersWithDetails] = useState([]);
  const [validatedDOData, setValidatedDOData] = useState(null);
  //eslint-disable-next-line
  const [tournee, setTournee] = useState({});
  const [tour, setTour] = useState(null);
  const [dropOffOrders, setDropOffOrders] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [ordersDropoffs, setOrdersDropoffs] = useState([]);

  //Fetch user by id
  const fetchUserById = async (id) => {
    try {
      const userData = await UsersDataService.getUserById(id);
      return userData.userData;
    } catch (error) {
      console.error("Error fetching user data", error);
    }
  };
  const fetchDOValidatedData = async () => {
    try {
      const querySnapshot = await TestDataService.getAllTest();
      const DOData = [];
      querySnapshot.forEach((doc) => {
        const tour = doc.data();
        if (tour.tours) {
          tour.tours.forEach((tourItem) => {
            if (tourItem.ordersDropoffs) {
              const orderDropoffs = tourItem.ordersDropoffs.filter(
                (order) =>
                  order.validation === true &&
                  order.date !== null &&
                  order.location !== null
              );
              DOData.push(...orderDropoffs);
            }
          });
        }
      });
      setValidatedDOData(DOData);
    } catch (error) {
      console.error("Error fetching DO data", error);
    }
  };

  // Fetch tournee data by selected date & set tour
  useEffect(() => {
    const fetchTourneeData = async () => {
      try {
        const tourneeData = await TestDataService.findTodayTournee(
          selectedDate,
          DriverID
        );
        if (tourneeData) {
          setTournee(tourneeData.todayTournee);
          setTour(tourneeData.toursData);
        } else {
          setTour(null);
          setTournee({});
        }
        setDataFetched(true);
      } catch (error) {
        console.error("Error fetching tournee data:", error);
      } finally {
        tour &&
          tour?.orders &&
          setDropOffOrders(
            tour?.orders
              .filter((order) => order?.orderType === "DO")
              .filter((order) =>
                tour?.ordersDropoffs.some(
                  (dropOff) =>
                    dropOff.orderID === order.id && dropOff.validation !== true
                )
              )
          );
        tour && setOrdersDropoffs(tour.ordersDropoffs);
      }
    };

    fetchTourneeData();
  }, [dataFetched]);

  // Fetch order data for each order ID in dropOffOrders
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const orderData = await OrdersDataService.getAllOrders();
        const parsedOrderssData = orderData.docs.map((doc) => doc.data());
        const orders = parsedOrderssData.filter((order) =>
          dropOffOrders
            .map((newOrder) => newOrder.id)
            .includes(order.idCommande)
        );

        // Fetch user data for each order
        const usersDataPromises = orders.map((order) =>
          fetchUserById(order.idClient)
        );
        const usersData = await Promise.all(usersDataPromises);

        // Combine order data with user data
        const ordersWithUserData = orders.map((order, index) => ({
          ...order,
          user: usersData[index],
        }));

        setOrdersWithDetails(ordersWithUserData);
      } catch (error) {
        console.error("Error fetching order data", error);
      }
    };

    fetchOrderDetails();
    fetchDOValidatedData();
  }, [dropOffOrders]);

  console.log("ordersWithDetails", ordersWithDetails);
  return (
    <div className="flex-col px-5 pt-5 h-screen bg-slate-800 text-slate-200">
      <div>
        <ArrowLeft
          size={40}
          className="mb-5 cursor-pointer p-1 border-2 border-slate-500 rounded-full"
          color="white"
          strokeWidth={3}
          onClick={() => navigate(-1)}
        />
      </div>

      <div className="px-5">
        <div className="text-3xl font-bold my-5">
          {`${shift.start}-${start}`}
        </div>
        <div className="h-0.5 w-full bg-slate-500 my-3"></div>
        <div className="flex justify-between items-center my-5">
          <div>
            <div className="text-xl font-bold">{hub.name}</div>
            <div className="text-sm text-slate-400">{hub.address}</div>
          </div>
          <NavigationButton navigationAdress={hub.address} size={40} />
        </div>
        <div className="h-0.5 w-full bg-slate-500 my-3"></div>
        {dropOffOrders &&
          (dropOffOrders.length !== 0 ? (
            ordersWithDetails
              .filter(
                (order) =>
                  !validatedDOData
                    .map((validatedOrder) => validatedOrder.orderID)
                    .includes(order.idCommande)
              )
              .map((order) => (
                <div>
                  <div className="text-xl font-bold mt-5">
                    Récupérer : ({dropOffOrders.length})
                  </div>
                  <div key={`${order.id}-${order.idFacture}`} className="my-5">
                    <DepotOrderCard
                      order={order}
                      ordersDropoffs={ordersDropoffs}
                    />
                  </div>
                </div>
              ))
          ) : (
            <Check size={200} color="teal" className="m-auto" />
          ))}
      </div>
    </div>
  );
}

export default DepotDetails;
