import React from "react";
import SacContent from "./components/SacContent";
import { Button } from "../../../../../@/components/ui/button";
import Notes from "./components/Notes";

function SignaturePage() {
  return (
    <div className="flex flex-col gap-y-2 mb-4">
      <div className="flex justify-between border-b-2 pb-3 mb-5">
        <div className="flex flex-col text-lg font-semibold text-slate-400">
          CLIENT
          <p className="text-white font-normal">MOUHA LA SQUALE</p>
        </div>
        <div className="flex flex-col text-lg font-semibold text-slate-400">
          SACS
          <p className="mx-auto text-white font-normal">2</p>
        </div>
      </div>

      <SacContent type="lk" count="1" />
      <SacContent type="pressing" count="1" />

      <Button
        variant="cleamy"
        className="text-lg font-mono uppercase tracking-widest py-8"
      >
        Confirmer les préférences
      </Button>

      <Notes />
    </div>
  );
}

export default SignaturePage;
