import { Backpack } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Card, CardContent } from "../../../@/components/ui/card";
import PrestaOrderById from "../../../lib/PrestaOrderById";

function DepotArrivalOrderCard({ order }) {

  const [prestaOrderDetails, setPrestaOrderDetails] = useState({});
  useEffect(() => {
    PrestaOrderById(order.idCommande, setPrestaOrderDetails);
  }, [order]);

  return (
    <Card>
      <CardContent className="p-2 h-fit hover:bg-slate-100 rounded-md cursor-pointer">
        <div className="flex flex-col gap-y-3">
          <div className="flex justify-between">
            <div className="flex flex-col gap-3">
              <p className="text-sm font-semibold">
                {order.idFacture}-PU
              </p>
              <p className="text-sm font-semibold">
                {order.user?.nom} {order.user?.prenom}
              </p>
            </div>
            <div className="flex flex-col gap-3">
              <Backpack size={20} />{" "}
              <p>XX</p>
              {/* <p className="text-sm font-bold mt-1 mx-1">
                {prestaOrderDetails.items && prestaOrderDetails.items.length}
              </p> */}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default DepotArrivalOrderCard;
