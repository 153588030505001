import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/private/Home/Home";
import DepotDetails from "./pages/private/DepotDetails/DepotDetails";
import SignIn from "./pages/private/SignIn/SignIn";
import { useAuth } from "./AuthContext";
import DepotOrderDetails from "./pages/private/DepotDetails/components/DepotOrderDetails";
import DepotDetailsArrival from "./pages/private/DepotDetailsArrival/DepotDetailsArrival";
import { NavigationProvider } from "./NavigationContext";
import OrderPage from "./pages/private/OrderPage/OrderPage";
import OrderPageB2B from "./pages/private/OrderPageB2B/OrderPageB2B";

function App() {
  const { userLoggedIn } = useAuth();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <NavigationProvider>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route
          path="/Home"
          element={
            userLoggedIn ? (
              <Home
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
              />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/DepotDetails"
          element={
            userLoggedIn ? (
              <DepotDetails selectedDate={selectedDate} />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/DepotDetailsArrival"
          element={
            userLoggedIn ? (
              <DepotDetailsArrival selectedDate={selectedDate} />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/DepotOrderDetails/:id"
          element={
            userLoggedIn ? (
              <DepotOrderDetails selectedDate={selectedDate} />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/OrderPage"
          element={userLoggedIn ? <OrderPage /> : <Navigate to="/" replace />}
        />
        <Route
          path="/OrderPageB2B"
          element={
            userLoggedIn ? (
              <OrderPageB2B selectedDate={selectedDate} />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
      </Routes>
    </NavigationProvider>
  );
}

export default App;
