import React, { useState } from "react";

function Notes() {
  const [selected, setSelected] = useState("sur cintre");

  return (
    <div className="flex flex-col border-t-2 py-5 mt-5">
      <div className="mx-auto text-lg font-mono uppercase tracking-widest text-slate-400">
        Préférence de chemises
      </div>
      <div className="flex justify-evenly text-lg font-normal uppercase text-white py-5">
        <div
          className={`cursor-pointer ${
            selected === "sur cintre" ? "border-b-2 border-teal-500" : ""
          }`}
          onClick={() => setSelected("sur cintre")}
        >
          sur cintre
        </div>
        <div
          className={`cursor-pointer ${
            selected === "plié" ? "border-b-2 border-teal-500" : ""
          }`}
          onClick={() => setSelected("plié")}
        >
          plié
        </div>
      </div>
      <div className="flex flex-col border-t-2">
        <div className="text-lg font-mono uppercase tracking-widest text-slate-400 py-5">
          Notes / recommandations
        </div>
        <textarea
          className="w-full h-24 text-lg font-normal border-2 border-dashed text-white bg-slate-700 p-3"
          placeholder="Notes"
        ></textarea>
      </div>
    </div>
  );
}

export default Notes;
