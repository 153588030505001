import React, { useEffect, useState } from "react";
import { Card, CardContent } from "../../../../@/components/ui/card";
import HubsDataService from "../../../../services/hubs.services";
import ShiftsDataService from "../../../../services/shifts.services";

import { Fence } from "lucide-react";
import { useNavigate } from "react-router-dom";

function DepotCard({ tournee, endDepot }) {
  const [hub, setHub] = useState({});
  const [shift, setShift] = useState({});

  // Fetch hub data by hub id & fetch shift
  const fetchHubData = async () => {
    try {
      const hubData = await HubsDataService.getHubById(
        tournee.departureAndArrivalPoint
      );
      setHub(hubData.data());
    } catch (error) {
      console.error("Error fetching hub data:", error);
    }
  };
  useEffect(() => {
    fetchHubData();
  }, [tournee]);

  //fetch shift data by driversTournee.evening
  const fetchShiftData = async () => {
    const shiftsData = await ShiftsDataService.getAllShifts();
    const parsedShiftsData = shiftsData.docs.map((doc) => doc.data());
    try {
      if (tournee?.tourType === "B2B") {
        const shiftData = parsedShiftsData.find(
          (shift) => shift.type === "B2B"
        );
        setShift(shiftData);
      } else {
        if (tournee?.evening) {
          const shift = parsedShiftsData.find(
            (shift) => shift.type === "Evening shift"
          );
          setShift(shift);
        } else {
          const shift = parsedShiftsData.find(
            (shift) => shift.type === "Morning Shift"
          );
          setShift(shift);
        }
      }
    } catch (error) {
      console.error("Error fetching shift data:", error);
    }
  };
  useEffect(() => {
    fetchShiftData();
  }, []);

  const navigate = useNavigate();

  let start = ""; // Définir une valeur par défaut pour start
  const [endHour, endMinute] = (shift?.start ?? "").split(":").map(Number);
  const startHour = endHour;
  const startMinute = endMinute + 15;
  start = `${startHour}:${startMinute.toString().padStart(2, "0")}`;

  let end = ""; // Définir une valeur par défaut pour end
  const [startHourEnd, startMinuteEnd] = (shift?.end ?? "")
    .split(":")
    .map(Number);
  const endHourEnd = startHourEnd;
  const endMinuteEnd = startMinuteEnd + 15;
  end = `${endHourEnd}:${endMinuteEnd.toString().padStart(2, "0")}`;

  const handleDetailsClick = () => {
    if (endDepot) {
      navigate("/DepotDetailsArrival", {
        state: { hub, shift, end },
      });
    } else {
      navigate("/DepotDetails/", {
        state: { hub, shift, start },
      });
    }
  };

  return (
    <div className="my-5 cursor-pointer">
      <Card className="rounded-md">
        <CardContent
          className="p-2 h-fit border-2 border-slate-500 rounded-md"
          onClick={handleDetailsClick}
        >
          <div className="flex flex-col gap-y-7">
            <div className="flex justify-between">
              <p className="flex gap-x-2 items-center text-sm font-semibold">
                <Fence size={20} color="blue" />
                {hub?.name}
              </p>
              <p className="text-sm font-semibold">
                {endDepot ? `${shift.end}-${end}` : `${shift.start}-${start}`}
              </p>
            </div>
            <p className="text-sm font-semibold">{hub?.address}</p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default DepotCard;
