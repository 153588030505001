import { Barcode, Check } from "lucide-react";
import React from "react";

function StartButtons() {
  return (
    <div className="mx-auto">
      <div className="flex flex-row justify-center gap-x-40 w-full p-1">
        <Check
          size={70}
          strokeWidth={2}
          color="white"
          className="bg-slate-500 p-2 rounded-full cursor-pointer"
        />
        <Barcode
          size={70}
          strokeWidth={2}
          color="white"
          className="bg-teal-500 p-2 rounded-full cursor-pointer"
        />
      </div>
    </div>
  );
}

export default StartButtons;
