import { Minus, Plus } from "lucide-react";
import React, { useState } from "react";

function PressingChoice() {
  const pressingItems = ["Chemise", "Costume", "Haut", "Bas", "Autre"];

  const [itemCounts, setItemCounts] = useState(
    pressingItems.reduce((acc, item) => ({ ...acc, [item]: 0 }), {})
  );

  const handleIncrement = (item) => {
    setItemCounts((prevCounts) => ({
      ...prevCounts,
      [item]: prevCounts[item] + 1,
    }));
  };

  const handleDecrement = (item) => {
    setItemCounts((prevCounts) => ({
      ...prevCounts,
      [item]: Math.max(prevCounts[item] - 1, 0),
    }));
  };

  return (
    <div className="flex flex-col gap-y-8 mt-3 overflow-scroll">
      {pressingItems.map((item) => (
        <div key={item} className="flex justify-between items-center">
          <div className="flex gap-x-10 items-center">
            <Minus
              size={30}
              color="black"
              className="rounded-full bg-white cursor-pointer"
              onClick={() => handleDecrement(item)}
            />
            <p className="text-xl border-b-2 px-2">{itemCounts[item]}</p>
          </div>
          <p className="text-xl">{item}</p>
          <Plus
            size={30}
            color="black"
            className="rounded-full bg-white cursor-pointer"
            onClick={() => handleIncrement(item)}
          />
        </div>
      ))}
    </div>
  );
}

export default PressingChoice;
