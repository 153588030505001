import React from "react";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from "../../../../../../@/components/ui/drawer";
import { Button } from "../../../../../../@/components/ui/button";

function InformClient({ isOpen, onOpenChange }) {
  return (
    <Drawer open={isOpen} onOpenChange={onOpenChange}>
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>EXPLICATION DU SERVICE LINGE AU KILO</DrawerTitle>
          <DrawerDescription>
            <div className="pt-5">
              <p>
                1. Tous les articles fournis par le service de Linge au Kilo
                doivent pouvoir être lavés à 40°C et être séchés au sèche-linge
              </p>
              <p>
                2. Ne pas fournir d'articles délicats dans le sac de Linge au
                Kilo
              </p>
              <p>
                3. Tous les articles de la même commande seront lavés ensemble
              </p>
            </div>
          </DrawerDescription>
        </DrawerHeader>
        <DrawerFooter>
          <Button>J'ai informé le client</Button>
          {/* <DrawerClose asChild>
            <Button variant="outline">Annuler</Button>
          </DrawerClose> */}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export default InformClient;
