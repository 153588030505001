import PrestaDataService from "../services/presta.services";

const PrestaOrderById = async (id, setPrestaOrderDetails) => {
  try {
    const prestaOrderData = await PrestaDataService.getPrestaByIdCommande(id);
    setPrestaOrderDetails(prestaOrderData);
  } catch (error) {
    console.error("Error fetching order details:", error);
  }
};

export default PrestaOrderById