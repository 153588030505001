import React from "react";

function SacContent({ type, count }) {
  return (
    <div className="flex justify-between border-b-2 pb-3 mb-5">
      <div className="flex flex-col text-lg font-semibold text-white">
        {type === "lk" ? "Linge au kilo" : "Pressing"}
        {type !== "lk" && (
            <div className="flex flex-col justify-start pt-3 gap-y-2 mb-4">
                <div className="text-md font-medium text-slate-400">
                    <p>1 x Chemises</p>
                    <p>2 x Pantalons</p>
                    <p>13 x Hauts</p>
                </div>
            </div>
        )}
      </div>
      <div className="flex flex-col text-lg font-semibold text-white">
        {count}
      </div>
    </div>
  );
}

export default SacContent;
