import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebaseConfig";

// Function to get user's current location
export const getCurrentLocation = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        resolve({ latitude, longitude });
      },
      (error) => {
        reject(error);
      }
    );
  });
};
const toursCollectionRef = collection(db, "Tours");

class TestDataService {
  getTourDateTimestamp(date) {
    const startDate = new Date(date.setHours(0, 0, 0, 0));
    const endDate = new Date(date.setHours(23, 59, 59, 999));
    const startTimestamp = Timestamp.fromDate(startDate);
    const endTimestamp = Timestamp.fromDate(endDate);
    return { startTimestamp, endTimestamp };
  }

  // Function to get the tours based on the provided date
  getTours = (date) => {
    const { startTimestamp, endTimestamp } = this.getTourDateTimestamp(date);

    const querySnapshot = query(
      toursCollectionRef,
      where("tourDate", ">=", startTimestamp),
      where("tourDate", "<=", endTimestamp),
      where("groupeStatus", "in", [1, 2]),
      orderBy("tourDate")
    );
    return getDocs(querySnapshot);
  };

  // Get the "Tours" subcollection documents
  getTournees = (tourId) => {
    const tourneesSnapshot = getDocs(collection(db, "Tours", tourId, "TOURS"));

    return tourneesSnapshot;
  };

  getAllTest = () => {
    return getDocs(collection(db, "Tours"));
  };

  findTodayTournee = async (date, driverID) => {
    try {
      const tourneeQuerySnapshot = await getDocs(
        query(
          collection(db, "Tours"),
          where("tourDate", "==", Timestamp.fromDate(date))
        )
      );

      const tournees = tourneeQuerySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      const filteredTournees = tournees.filter(
        (tournee) => tournee.groupeStatus === 1 || tournee.groupeStatus === 2
      );

      if (filteredTournees.length === 0) {
        console.error("No matching tournee found");
        return null;
      }

      const TOURSPromises = filteredTournees.map((tournee) =>
        getDocs(collection(db, "Tours", tournee.id, "TOURS"))
      );

      const toursSnapshots = await Promise.all(TOURSPromises);
      const toursData = toursSnapshots.map((snapshot) =>
        snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );

      // Flatten the array of arrays into a single array
      const flattenedToursData = toursData.flat();

      return flattenedToursData;
    } catch (error) {
      console.error("Error finding today's tournee:", error);
      return null;
    }
  };

  updateValidationStatus = async (
    date,
    driverId,
    orderId,
    validationStatus
  ) => {
    try {
      // Find today's tournee
      const { todayTournee, toursData } = await this.findTodayTournee(
        date,
        driverId
      );
      if (!todayTournee) {
        console.error("Today's tournee not found");
        return null;
      }

      if (!toursData) {
        console.error("Driver's tour not found");
        return null;
      }

      // Find the index of the orderDropoff to update
      const ordersDropoffsIndex = toursData.ordersDropoffs.findIndex(
        (dropoff) => dropoff.orderID === orderId
      );
      if (ordersDropoffsIndex === -1) {
        console.error("Order dropoff not found in the tour data");
        return null;
      }

      // Update the specific order within ordersDropoffs
      const updatedOrdersDropoffs = toursData.ordersDropoffs.map(
        async (order) => {
          if (order.orderID === orderId) {
            console.log("Updating order:", order);
            const currentDate = new Date(); // Get current date and time
            const userLocation = await getCurrentLocation(); // Get user's current location

            // Return the updated order
            return {
              ...order,
              validation: validationStatus,
              date: currentDate,
              location: userLocation,
            };
          } else {
            // Return the original order if it doesn't match the condition
            return order;
          }
        }
      );

      // Wait for all the promises in the map function to resolve
      const updatedOrdersDropoffsResolved = await Promise.all(
        updatedOrdersDropoffs
      );

      // Update the ordersDropoffs array in Firestore
      const tourDocRef = doc(
        db,
        "Tours",
        todayTournee.id,
        "TOURS",
        toursData.id
      );
      await updateDoc(tourDocRef, {
        ordersDropoffs: updatedOrdersDropoffsResolved,
      });

      console.log("Order validation status updated successfully");
      return true;
    } catch (error) {
      console.error("Error updating order validation status:", error);
      return null;
    }
  };

  async updateTourSteps(date, driverId, newTour) {
    try {
      // Find today's tournee
      const { todayTournee, toursData } = await this.findTodayTournee(
        date,
        driverId
      );
      if (!todayTournee) {
        console.error("Today's tournee not found");
        return null;
      }

      if (!toursData) {
        console.error("Driver's tour not found");
        return null;
      }

      // Get a reference to the tour document
      const tourDocRef = doc(
        db,
        "Tours",
        todayTournee.id,
        "TOURS",
        toursData.id
      );
      const tourDocSnap = await getDoc(tourDocRef);

      if (!tourDocSnap.exists()) {
        console.error("Tour document not found");
        return null;
      }

      // Ensure the orders array exists in the document data
      const currentData = tourDocSnap.data();
      if (!currentData.orders) {
        console.error("Orders array not found in the document");
        return null;
      }

      const currentOrders = currentData.orders;

      // Replace the specific element in the orders array
      const updatedOrders = currentOrders.map((order) =>
        order.id === newTour.id ? newTour : order
      );

      // Update the tour document with the new orders array
      await updateDoc(tourDocRef, { orders: updatedOrders });

      console.log("Tour steps updated successfully");
      return true;
    } catch (error) {
      console.error("Error updating tour steps:", error);
      return null;
    }
  }

  /* updateTourSteps = async (date, driverId, newTour) => {
    try {
      // Find today's tournee
      const { todayTournee, toursData } = await this.findTodayTournee(
        date,
        driverId
      );
      if (!todayTournee) {
        console.error("Today's tournee not found");
        return null;
      }

      if (!toursData) {
        console.error("Driver's tour not found");
        return null;
      }

      // Update the tour steps in Firestore
      const tourDocRef = doc(
        db,
        "Tours",
        todayTournee.id,
        "TOURS",
        toursData.id
      );
      console.log("Updating tour steps:", newTour);
      await updateDoc(tourDocRef, newTour);

      console.log("Tour steps updated successfully");
      return true;
    } catch (error) {
      console.error("Error updating tour steps:", error);
      return null;
    }
  }; */
}
//eslint-disable-next-line
export default new TestDataService();
