import { Navigation2 } from "lucide-react";
import React from "react";
import { useNavigationContext } from "../../../../NavigationContext";

function NavigationButton({ navigationAdress, size }) {
  const { defaultNavigationApp, setDefaultNavigationApp } =
    useNavigationContext();

  function openWazeWithDestination(address) {
    const destination = encodeURIComponent(address);
    const wazeUrl = `https://waze.com/ul?q=${destination}`;
    window.open(wazeUrl);
  }

  const handleNavigation = (app) => {
    if (app === "google") {
      window.open(
        `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
          navigationAdress
        )}&travelmode=driving`,
        "_blank"
      );
    } else if (app === "waze") {
      openWazeWithDestination(navigationAdress);
    }
  };

  return (
    <div className="relative">
      <Navigation2
        size={size}
        className="p-1 rounded-md bg-slate-300 cursor-pointer mr-2"
        color="black"
        onClick={() => handleNavigation(defaultNavigationApp)}
      />
    </div>
  );
}

export default NavigationButton;
