import { auth } from "./firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";

export const doSignInWithEmailAndPassword = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password).then(
    (userCredential) => {
      const user = userCredential.user;
      console.log("User signed in:", user);
    }
  );
};

export const doSignOut = () => {
  return auth.signOut();
};
