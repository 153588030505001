import React, { useState, useEffect } from "react";
import { Card, CardContent } from "../../../../@/components/ui/card";
import {
  Truck,
  ShoppingBag,
  Lightbulb,
  AlertTriangle,
  Check,
} from "lucide-react";
import { cn } from "../../../../@/lib/utils";
import UsersDataService from "../../../../services/users.services";

import { useNavigate } from "react-router";

function OrderCard({ order, tour }) {
  const [tourCardOrder, setTourCardOrder] = useState({});
  const [tourCardOrderIndex, setTourCardOrderIndex] = useState(0);
  const [client, setClient] = useState({});
  const [adressesClient, setAdressesClient] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const tourCardOrder = tour?.orders.find(
      (tourOrder) => tourOrder.orderUnder[0].orderId === order.idCommande
    );
    const tourOrderIndex = tour?.orders.findIndex(
      (tourOrder) => tourOrder.orderUnder[0].orderId === order.idCommande
    );
    const newTourCardOrder = {
      ...tourCardOrder,
      /* index: tourCardOrderIndex, */
    };

    setTourCardOrder(newTourCardOrder);
    setTourCardOrderIndex(tourOrderIndex);
  }, [tour, order]);

  const fetchUser = async () => {
    try {
      const user = await UsersDataService.getUserById(order.idClient);
      setClient(user.userData);
      setAdressesClient(user.adressesData);
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };
  useEffect(() => {
    fetchUser();
  }, [order]);

  const handleSacManquant = (idCommande) => {
    const orderDropoff = tour.ordersDropoffs.find(
      (orderDropoff) => orderDropoff.orderID === idCommande
    );
    if (orderDropoff) {
      if (orderDropoff.validation === false) {
        return true;
      } else if (orderDropoff.validation === null) {
        return false;
      }
    }
    return false; // Default return value if orderDropoff is not found or its validation is true
  };

  return (
    <div>
      {order && tourCardOrder?.orderUnder && (
        <div className="p-1 my-3 cursor-pointer">
          <Card>
            <CardContent className={cn("p-2 h-fit rounded-md bg-teal-100")}>
              <div
                className="flex flex-col gap-y-3"
                onClick={() =>
                  navigate("/OrderPage", {
                    state: {
                      order,
                      tourCardOrder,
                      client,
                      adressesClient,
                      tourCardOrderIndex,
                    },
                  })
                }
              >
                <div className="flex justify-between">
                  <p className="flex gap-x-2 items-center text-sm font-semibold">
                    {tourCardOrder.orderStatus === 2 ? (
                      <Check size={20} color="#008080" />
                    ) : tourCardOrder?.orderUnder[0]?.orderType === "DO" ? (
                      <Truck size={20} color="#008080" />
                    ) : (
                      <ShoppingBag size={20} color="red" />
                    )}
                    {order?.idFacture}
                    {tourCardOrder?.orderUnder[0]?.orderType === "DO"
                      ? "-DO"
                      : "-PU"}
                    {client && client?.nbCommandes > 1 ? (
                      ""
                    ) : (
                      <Lightbulb
                        size={20}
                        color="#FFCC5C"
                        strokeWidth={3.5}
                        className="pb-1"
                        alt="Nouveau Client"
                        title="Nouveau Client"
                      />
                    )}
                  </p>
                  <div className="flex gap-2">
                    {handleSacManquant(order.idCommande) ? (
                      <AlertTriangle size={18} color="red" strokeWidth={3} />
                    ) : null}
                    <p className="text-sm font-semibold">
                      {tourCardOrder?.orderUnder[0]?.orderType === "DO"
                        ? order.creneauLivraison
                        : order.creneauCollecte}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between">
                  <p className="text-sm font-semibold">
                    {client.nom} {client.prenom}
                  </p>
                  <p className="text-sm font-semibold">{order.service}</p>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      )}
    </div>
  );
}

export default OrderCard;
