import {
  ArrowBigLeft,
  ArrowBigRight,
  ArrowLeft,
  Lightbulb,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { cn } from "../../../@/lib/utils";
import NavigationButton from "../DepotDetails/components/NavigationButton";
import Articles from "./components/Articles";
import StartButtons from "./components/FirstOrderPage/StartButtons";
import { GeoPoint } from "firebase/firestore";
import TestDataService from "../../../services/test.services";
import CollecteTypePage from "./components/CollecteTypePage/CollecteTypePage";
import FirstOrderPage from "./components/FirstOrderPage/FirstOrderPage";
import CollecteTypePageButtons from "./components/CollecteTypePage/CollecteTypePageButtons";
import Recap from "./components/Recap/Recap";
import SignaturePage from "./components/SignaturePage/SignaturePage";

const DriverID = "LOXw3WgAf0XL9D6QdvWba92RNVF2";

function OrderPage() {
  const { state } = useLocation();
  const { order, tourCardOrder, client, adressesClient, tourCardOrderIndex } =
    state;
  const navigate = useNavigate();
  const [newTour, setNewTour] = useState(tourCardOrder);
  const [pageSelected, setPageSelected] = useState(2);

  console.log("newTour", newTour);
  console.log("GEOPOINT", new GeoPoint(48.8566, 2.3522));

  console.log("tourCardOrder", tourCardOrder);

  return (
    <div className="h-screen overflow-hidden bg-slate-800 text-slate-200">
      {tourCardOrder?.orderUnder && (
        <>
          <div className="flex flex-col justify-start pb-10 pt-2 overflow-auto h-5/6 p-5">
            <div className="flex mb-2">
              <ArrowLeft
                size={35}
                className="cursor-pointer p-1 border-2 border-slate-500 rounded-full"
                color="white"
                strokeWidth={3}
                onClick={() => navigate(-1)}
              />
              <div className="flex m-0 p-0">
                <ArrowBigLeft
                  size={20}
                  className="mb-5 cursor-pointer"
                  onClick={() => setPageSelected(1)}
                />
                <ArrowBigRight
                  size={20}
                  className="mb-5 cursor-pointer"
                  onClick={() => setPageSelected(2)}
                />
                <ArrowBigLeft
                  size={20}
                  className="mb-5 cursor-pointer"
                  onClick={() => setPageSelected(3)}
                />
                <ArrowBigLeft
                  size={20}
                  className="mb-5 cursor-pointer"
                  onClick={() => setPageSelected(4)}
                />
              </div>
            </div>
            {pageSelected === 1 ? (
              <FirstOrderPage
                tourCardOrder={tourCardOrder}
                order={order}
                client={client}
                adressesClient={adressesClient}
              />
            ) : pageSelected === 2 ? (
              <CollecteTypePage />
            ) : pageSelected === 3 ? (
              <Recap />
            ) : pageSelected === 4 ? (
              <SignaturePage />
            ) : null}
          </div>
          <div className="relative h-1/6">
            <div className="flex items-center w-screen absolute bottom-0 left-0 bg-gradient-to-r from-indigo-950 to-indigo-800/40 border-y-4 border-gray-800 p-1 fixed-bottom justify-start shadow-[inset_0_-2px_10px_rgba(0,0,0,0.9)]">
              {pageSelected === 1 ? (
                <StartButtons />
              ) : pageSelected === 2 ? (
                <CollecteTypePageButtons />
              ) : pageSelected === 3 ? (
                <StartButtons />
              ) : null}

              {/* <SwipeButton state="livraison" tourCardOrder={tourCardOrder} /> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default OrderPage;
