import { Lightbulb } from "lucide-react";
import React from "react";
import NavigationButton from "../../../DepotDetails/components/NavigationButton";
import Articles from "../Articles";

function FirstOrderPage({ tourCardOrder, order, client, adressesClient }) {
  return (
    <div>
      <div className="flex flex-col gap-y-2 mb-4">
        <div className="text-lg font-semibold text-slate-400">
          {tourCardOrder?.orderUnder[0]?.orderType === "DO"
            ? "LIVRAISON"
            : "RECUPERATION"}{" "}
          [{order.idFacture}
          {tourCardOrder?.orderUnder[0]?.orderType === "DO" ? "-DO" : "-PU"}]
        </div>
        <div className="text-3xl font-bold">
          {tourCardOrder?.orderUnder[0]?.orderType === "DO"
            ? order.creneauLivraison
            : order.creneauCollecte}
        </div>
      </div>
      <div className="flex justify-between items-center border-t-2 pt-3 mb-4">
        <div className="flex flex-col gap-y-2">
          <div className="text-xl font-bold tracking-wider">COMMANDE</div>
          <div className="text-md font-semibold  text-slate-400">
            {order?.idFacture}
          </div>
        </div>
        <div className="flex flex-col gap-y-2">
          <div className="text-xl font-bold tracking-wider">SERVICE</div>
          <div className="flex items-center gap-x-2 text-md font-semibold  text-slate-400">
            {order?.service === "Prime" ? (
              <div className="bg-red-500 h-3 w-3 rounded-full"></div>
            ) : (
              <div className="bg-teal-500 h-3 w-3 rounded-full"></div>
            )}{" "}
            {order?.service}
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-start border-t-2 pt-3 gap-y-2 mb-4">
        <div className="text-xl font-bold tracking-wider">CLIENT</div>
        <div className="flex text-md font-medium  text-slate-400">
          {client?.nom} {client?.prenom}
          {client && client?.nbCommandes > 1 ? (
            ""
          ) : (
            <Lightbulb
              size={20}
              color="#FFCC5C"
              strokeWidth={3.5}
              className="pb-1"
              alt="Nouveau Client"
              title="Nouveau Client"
            />
          )}
        </div>
      </div>
      <div className="flex justify-between items-center border-t-2 pt-3 mb-4">
        <div className="flex flex-col gap-y-2">
          <div className="text-xl font-bold tracking-wider">ADRESSE</div>
          <div className="text-sm font-medium  text-slate-400">
            {order?.adresse} | {adressesClient[0]?.informationCompl}
          </div>
        </div>
        <div className="ms-5">
          <NavigationButton navigationAdress={order?.adresse} size={40} />
        </div>
      </div>

      {tourCardOrder?.orderUnder[0]?.orderType === "DO" ? (
        <Articles order={tourCardOrder?.orderUnder[0]} />
      ) : null}
    </div>
  );
}

export default FirstOrderPage;
