import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";

class PrestaDataService {
  getAllPresta = () => {
    return getDocs(collection(db, "Prestataire"));
  };

  getPrestaByIdCommande = async (idCommande) => {
    const q = query(
      collection(db, "Prestataire"),
      where("id", "==", idCommande)
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      return querySnapshot.docs[0].data();
    } else {
      return null;
    }
  };
}

export default new PrestaDataService();
