import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";

class UsersDataService {
  getAllUsers = () => {
    return getDocs(collection(db, "Users"));
  };

  getUserById = async (id) => {
    try {
      const userDocRef = doc(db, "Users", id);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();

        // "adresses" est une subcollection du document "user"
        const adressesCollectionRef = collection(userDocRef, "adresses");
        const adressesSnapshot = await getDocs(adressesCollectionRef);
        const adressesData = adressesSnapshot.docs.map((doc) => doc.data());

        return { userData, adressesData };
      } else {
        throw new Error("User document not found");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
  };
}
//eslint-disable-next-line
export default new UsersDataService();
