import { Menu } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Button } from "../../../../@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuLabel,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "../../../../@/components/ui/dropdown-menu";
import { doSignOut } from "../../../../auth";
import { Checkbox } from "../../../../@/components/ui/checkbox";
import { useNavigationContext } from "../../../../NavigationContext";

function Navbar() {
  const { defaultNavigationApp, setDefaultNavigationApp } =
    useNavigationContext();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await doSignOut();
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleToggleMenu = () => {
    if (!open) {
      setOpen(true);
    } else {
      setTimeout(() => {
        setOpen(false);
      }, 500);
    }
  };

  return (
    <DropdownMenu open={open} onOpenChange={handleToggleMenu}>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="sm">
          <Menu size={20} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[200px]">
        <DropdownMenuLabel>Actions :</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuLabel>
          Choisir App de navigation par default :
        </DropdownMenuLabel>
        <DropdownMenuGroup>
          <DropdownMenuItem onClick={() => setDefaultNavigationApp("waze")}>
            <Checkbox
              id="Waze"
              checked={defaultNavigationApp === "waze" ? true : false}
            />
            <label htmlFor="Waze" className="text-sm font-medium ms-5 p-3">
              Waze
            </label>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setDefaultNavigationApp("google")}>
            <Checkbox
              id="Google"
              checked={defaultNavigationApp === "google" ? true : false}
            />
            <label htmlFor="Google" className="text-sm font-medium ms-5 p-3">
              Google
            </label>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            className="text-red-600 cursor-pointer"
            onClick={handleSignOut}
          >
            Se déconnecter
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default Navbar;
