import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";

const ordersCollectionRef = collection(db, "orders");

class OrdersDataService {
  getAllOrders = () => {
    return getDocs(collection(db, "orders"));
  };
  getOrderById = (id) => {
    const orderDocRef = doc(db, "orders", id);
    return getDoc(orderDocRef);
  };
  getArticlesDispatch = async (idOrder) => {
    try {
      const orderDoc = doc(db, "orders", idOrder);
      const result = await getDoc(orderDoc);
      const idDispatch = result.data().refDispatch;
      const dispatchDoc = doc(db, "Dispatch_orders", idDispatch);
      return await getDoc(dispatchDoc);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des articles de dispatch:",
        error
      );
    }
  };
}
export default new OrdersDataService();
