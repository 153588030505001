import { AlertTriangle, ArrowLeft, Check, ScanBarcode } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import PrestaOrderById from "../../../../lib/PrestaOrderById";
import TestDataService from "../../../../services/test.services";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../../../../src/@/components/ui/alert-dialog";
import LoadingCircle from "../../../../lib/LoadingCircle/LoadingCircle";
import { Button } from "../../../../@/components/ui/button";

const DriverID = "12SqhgPLqGaK9mEhsSIX6RDvsZT2";

function DepotOrderDetails({ selectedDate }) {
  const { id } = useParams();
  const { state } = useLocation();
  const { order, orderDropoff } = state;
  const [prestaOrderDetails, setPrestaOrderDetails] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    PrestaOrderById(id, setPrestaOrderDetails);
  }, [id]);

  const handleValidation = async () => {
    try {
      const numCommande = document.getElementById("NumCommande").value;
      if (numCommande.toUpperCase() === order.idFacture) {
        setLoading(true);
        // Update validation status to true
        await TestDataService.updateValidationStatus(selectedDate, DriverID, id, true);
        PrestaOrderById(id, setPrestaOrderDetails);
        setTimeout(() => {
          setLoading(false);
        }, 4000);
        setDataFetched(true);
      } else {
        alert("Numéro de commande incorrect");
      }
    } catch (error) {
      console.error("Error handling validation:", error);
    }
  };

  const handleSacManquant = async () => {
    try {
      const numCommande = document.getElementById("NumCommande").value;
      if (numCommande.toUpperCase() === order.idFacture) {
        setLoading(true);
        // Update validation status to true
        await TestDataService.updateValidationStatus(
          selectedDate,
          DriverID,
          id,
          false
        );
        PrestaOrderById(id, setPrestaOrderDetails);
        setTimeout(() => {
          setLoading(false);
          navigate(-1);
        }, 4000);
        setDataFetched(true);
      } else {
        alert("Numéro de commande incorrect");
      }
    } catch (error) {
      console.error("Error handling validation:", error);
    }
  };

  console.log("orderDropoff", orderDropoff);

  return (
    <div className="flex flex-col h-screen justify-between px-10 pb-10 pt-7 overflow-hidden">
      {loading ? (
        <LoadingCircle className="my-auto" />
      ) : (
        <div>
          {/** icon + articles */}
          <div>
            <ArrowLeft
              size={40}
              className="mb-5 cursor-pointer p-1 border-2 border-slate-500 rounded-full"
              color="gray"
              strokeWidth={3}
              onClick={() => navigate(-1)}
            />
            <div className="px-5">
              <div className="flex justify-between">
                <div className="flex flex-col items-start gap-y-2">
                  <div className="flex justify-between items-center">
                    <div className="text-xl text-slate-500 font-bold">
                      {order.idFacture}
                    </div>
                    {orderDropoff &&
                    orderDropoff.validation === false &&
                    !dataFetched ? (
                      <div className="flex text-xs font-bold text-red-500 ms-5">
                        <AlertTriangle size={20} color="red" strokeWidth={3} />
                        <p className="flex text-center items-center">
                          sac manquant
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <div className="text-md font-bold">
                    {order.user.nom} {order.user.prenom}
                  </div>
                </div>
                {/* <div className="flex flex-col items-end gap-y-2">
              <div className="text-xl text-slate-500 font-bold">SACS</div>
              <div className="text-md font-bold">
                {prestaOrderDetails.items && prestaOrderDetails.items.length}
              </div>
            </div> */}
              </div>
              <div className="h-0.5 w-full bg-slate-500 my-3"></div>
              {/** a list of items dans le order */}
              <div>
                {/* List of all sacs */}
                {/* {prestaOrderDetails.items &&
              prestaOrderDetails.items.map((item, index) => (
                <div key={index}>
                  <div className="flex text-lg font-bold my-2 gap-x-2">
                    Sac {index + 1} :{" "}
                    <p className="text-md font-normal font-mono text-slate-600">
                      {item.Nsac}
                    </p>
                  </div>
                </div>
              ))}
            <div className="h-0.5 w-full bg-slate-500 my-3"></div> */}
                {/* List of all items of all sacs */}
                <p className="text-lg font-bold py-2">Articles :</p>
                <div className="flex flex-col justify-center overflow-scroll h-32 p-2 rounded-md bg-slate-100 mb-20">
                  {prestaOrderDetails.items &&
                    prestaOrderDetails.items.map((item, index) => (
                      <div key={index}>
                        {/* List of articles for each sac */}
                        {item.articles &&
                          item.articles.map((article, idx) => (
                            <div key={idx} className="flex mb-2">
                              <p className="font-bold me-2">{article.qte} x</p>
                              {article.id}
                            </div>
                          ))}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          {/* Bouttons */}
          {dataFetched ? (
            <div className="flex justify-center items-center w-full p-2 mb-2 bg-green-400 rounded-md fixed-bottom">
              <Check
                size={40}
                strokeWidth={3}
                color="white"
                onClick={() => navigate(-1)}
              />
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center text-lg font-semibold p-2 mb-2 rounded-md fixed-bottom">
              {orderDropoff && orderDropoff.validation === false ? null : (
                <AlertDialog>
                  <AlertDialogTrigger className="flex justify-center items-center mb-2 w-full bg-red-400 hover:bg-red-500 rounded-md">
                    <Button
                      className="text-sm font-semibold p-1"
                      variant="personal"
                    >
                      Sac manquant
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>
                        êtes-vous sûrs de signaler ce sac comme MANQUANT ?
                      </AlertDialogTitle>
                      <AlertDialogDescription>
                        <div className="flex flex-col items-start gap-y-3 my-10">
                          <label className="font-medium">
                            Numéro de commande :
                          </label>
                          <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="NumCommande"
                            type="text"
                            placeholder="Numéro de commande"
                          />
                        </div>
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Annuler</AlertDialogCancel>
                      <AlertDialogAction onClick={handleSacManquant}>
                        Valider
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              )}

              <AlertDialog>
                <AlertDialogTrigger className="flex justify-center items-center w-full bg-green-400 hover:bg-green-500 rounded-md">
                  <Button className="p-4" variant="personal">
                    <ScanBarcode size={30} strokeWidth={2} />
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>
                      êtes-vous sûrs de valider la commande ?
                    </AlertDialogTitle>
                    <AlertDialogDescription>
                      <div className="flex flex-col items-start gap-y-3 my-10">
                        <label className="font-medium">
                          Numéro de commande :
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="NumCommande"
                          type="text"
                          placeholder="Numéro de commande"
                        />
                      </div>
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Annuler</AlertDialogCancel>
                    <AlertDialogAction onClick={handleValidation}>
                      Valider
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
              <div className="flex justify-center items-center mb-10 rounded-md fixed-bottom"></div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default DepotOrderDetails;
