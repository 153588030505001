import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";

const ordersb2bCollectionRef = collection(db, "Orders_B2B");

class OrdersB2bDataService {
  getAllOrdersB2b = () => {
    return getDocs(collection(db, "Orders_B2B"));
  };
  getOrderB2bByuser = (user) => {
     const orderDocRef = doc(db, "Orders_B2B", user);
     return getDoc(orderDocRef);
  };
}
export default new OrdersB2bDataService();
