import React, { createContext, useContext, useState } from "react";

const NavigationContext = createContext();

export const useNavigationContext = () => useContext(NavigationContext);

export const NavigationProvider = ({ children }) => {
  const [defaultNavigationApp, setDefaultNavigationApp] = useState("waze");

  return (
    <NavigationContext.Provider
      value={{ defaultNavigationApp, setDefaultNavigationApp }}
    >
      {children}
    </NavigationContext.Provider>
  );
};
