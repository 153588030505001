import { ArrowLeft, Lightbulb, PhoneCall } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { cn } from "../../../@/lib/utils";
import NavigationButton from "../DepotDetails/components/NavigationButton";
import SwipeButton from "./components/SwipeButton";

function OrderPageB2B({ selectedDate }) {
  const { state } = useLocation();
  const { orderB2b, corporate, tourOrders, tour } = state;
  const [tourEditor, setTourEditor] = useState(tour);
  //orderStep -> 0: recuperation, 1: en route, 2: arrivé, 3: terminé, 4: annulé
  const [orderStep, setOrderStep] = useState(0);
  const navigate = useNavigate();
  const [underUser, setUnderUser] = useState({});

  useEffect(() => {
    const under_User = orderB2b?.under_user.find(
      (user) => user.number_order === tourOrders?.orderUnder[0].orderNumber
    );
    setUnderUser(under_User);
  }, [orderB2b, tourOrders]);

  console.log("orderB2b", orderB2b);
  console.log("corporate", corporate);
  console.log("tour", tour);
  console.log("tourOrders", tourOrders);
  console.log("**********************************************");

  return (
    <div className="flex flex-col h-screen justify-between pb-10 pt-5 overflow-hidden">
      <div className="px-10">
        <div>
          <ArrowLeft
            size={40}
            className="mb-5 cursor-pointer p-1 border-2 border-slate-500 rounded-full"
            color="gray"
            strokeWidth={3}
            onClick={() => navigate(-1)}
          />
        </div>
        {tourOrders?.orderUnder.map((orderU) => (
          <div className="px-5 flex-grow">
            <div className="flex flex-col">
              <div className="text-md font-semibold text-slate-500">
                {orderU.orderType === "DO" ? "LIVRAISON" : "RECUPERATION"} [
                {orderU.orderNumber}
                {orderU.orderType === "DO" ? "-DO" : "-PU"}]
              </div>
              <div className="text-3xl font-bold my-2">
                {corporate.slot_start} - {corporate.slot_end}
              </div>
            </div>
            <div className="h-0.5 w-full bg-slate-500 my-3"></div>
            <div className="flex justify-between items-center my-5">
              <div className="flex flex-col">
                <div className="text-xl font-bold">ENTREPRISE</div>
                <div className="text-md font-semibold text-slate-500">
                  {corporate.nameCompany}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-xl font-bold">SERVICE</div>
                <div className="flex items-center gap-x-2 text-md font-semibold text-slate-500">
                  {underUser.service}{" "}
                  {underUser.service === "Prime" ? (
                    <div className="bg-red-500 h-3 w-3 rounded-full"></div>
                  ) : (
                    <div className="bg-teal-500 h-3 w-3 rounded-full"></div>
                  )}
                </div>
              </div>
            </div>
            <div className="h-0.5 w-full bg-slate-500 my-3"></div>
            <div className="flex justify-between items-center">
              <div className="flex flex-col">
                <div className="text-xl font-bold">ADRESSE & TEL</div>
                <div className="flex flex-col text-sm font-medium text-slate-500 gap-y-3">
                  <p>{corporate.adressLivraisonCompany}</p>{" "}
                  <a
                    href={`tel:${corporate.telCompany}`}
                    className="flex gap-3 text-blue-600 underline"
                  >
                    <PhoneCall size={20} />
                    {corporate.telCompany}
                  </a>
                </div>
              </div>
              <div className="ms-5">
                <NavigationButton
                  navigationAdress={corporate.adressLivraisonCompany}
                  size={40}
                />
              </div>
            </div>
            <div className="h-5 w-full bg-slate-500 my-3"></div>
          </div>
        ))}
      </div>
      {/* <div
        className={cn(
          "flex items-center w-screen bg-gradient-to-r from-indigo-950 to-indigo-800/40 border-y-4 border-gray-800 p-1 mb-2 fixed-bottom justify-start shadow-[inset_0_-2px_10px_rgba(0,0,0,0.9)]"
        )}
      >
        {tourCardOrder?.start?.date === null &&
        tourCardOrder?.start?.location === null ? (
          <SwipeButton
            state="livraison"
            tourEditor={tourEditor}
            setTourEditor={setTourEditor}
            tourCardOrder={tourCardOrder}
            selectedDate={selectedDate}
          />
        ) : tourCardOrder?.start?.date !== null &&
          tourCardOrder?.start?.location !== null &&
          tourCardOrder?.arrived?.location === null &&
          tourCardOrder?.arrived?.location === null ? (
          <SwipeButton
            state="arrive"
            tourEditor={tourEditor}
            setTourEditor={setTourEditor}
            tourCardOrder={tourCardOrder}
            selectedDate={selectedDate}
          />
        ) : (
          <SwipeButton
            state="terminer"
            tourEditor={tourEditor}
            setTourEditor={setTourEditor}
            tourCardOrder={tourCardOrder}
            selectedDate={selectedDate}
          />
        )}
      </div> */}
    </div>
  );
}

export default OrderPageB2B;
