import React from 'react'

function RecapCard({ sacNumber, type }) {
  return (
    <div className="flex justify-between bg-slate-700 border-x-4 border-dashed border-x-slate-300 p-3 my-2">
      <div className="flex flex-col text-lg font-semibold text-slate-400">
        {sacNumber}
        <p className="text-white font-normal">{type}</p>
      </div>
    </div>
  );
}

export default RecapCard