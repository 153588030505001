import React, { useEffect, useState } from "react";
import { Card, CardContent } from "../../../../@/components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../../../@/components/ui/carousel";
import formatDate from "../../../../lib/formatDate";

function DateCarousel({
  dates,
  setSelectedDate,
  selectedDate,
  activeIndex,
  setActiveIndex,
}) {

  const handlePreviousClick = () => {
    const newIndex = activeIndex === 0 ? dates.length - 1 : activeIndex - 1;
    setActiveIndex(newIndex);
    setSelectedDate(dates[newIndex]);
  };

  const handleNextClick = () => {
    const newIndex = activeIndex === dates.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(newIndex);
    setSelectedDate(dates[newIndex]);
  };

  return (
    <div className="flex justify-center mt-5">
      <Carousel
        className="w-full max-w-52 md:max-w-md"
        opts={{
          align: "start",
          loop: true,
        }}
      >
        <CarouselContent className="-ml-1">
          {dates.map((date, index) => (
            <CarouselItem key={index} className="pl-1">
              <div className="p-1">
                <Card className="flex justify-center">
                  <CardContent className="flex aspect-square items-center justify-center p-6 h-14 w-full">
                    <span className="text-xl font-semibold">
                      {selectedDate && activeIndex === 0
                        ? "Aujourd'hui"
                        : formatDate(selectedDate)}
                    </span>
                  </CardContent>
                </Card>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious
          onClick={handlePreviousClick}
          disabled={activeIndex === 0}
        />
        <CarouselNext
          onClick={handleNextClick}
          disabled={activeIndex === dates.length - 1}
        />
      </Carousel>
    </div>
  );
}

export default DateCarousel;
