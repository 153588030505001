import React, { useEffect, useState } from "react";
import OrdersDataService from "../../../../services/orders.services";

function Articles({ order }) {
  const [articlesByOrderId, setArticlesByOrderId] = useState({});
  useEffect(() => {
    const fetchArticlesForOrder = async (orderId) => {
      try {
        const docSnapUser = await OrdersDataService.getArticlesDispatch(
          orderId
        );
        setArticlesByOrderId((prevArticles) => ({
          ...prevArticles,
          [orderId]: docSnapUser.data().item,
        }));
      } catch (err) {
        console.log(err.message);
      }
    };
    fetchArticlesForOrder(order.orderId);
  }, [order]);

  console.log("articlesByOrderId", articlesByOrderId);
  return (
    <div className="flex flex-col justify-start border-t-2 pt-3 gap-y-2 mb-4">
      <div className="text-xl font-bold tracking-wider">ARTICLES</div>
      <div className="text-md font-medium text-slate-400">
        {articlesByOrderId[order.orderId] &&
          articlesByOrderId[order.orderId].map((article, idx) => (
            <p key={idx}>
              {article.qte} x {article.article}
            </p>
          ))}
        {!articlesByOrderId[order.orderId] && <p>Chargement des articles...</p>}
      </div>
    </div>
  );
}

export default Articles;
