import React, { useState, useEffect } from "react";
import TestDataService from "../../../services/test.services";
import HubsDataService from "../../../services/hubs.services";
import ShiftsDataService from "../../../services/shifts.services";
import OrdersDataService from "../../../services/orders.services";
import OrdersB2bDataService from "../../../services/ordersb2b.services";

import DateCarousel from "./components/DateCarousel";
import DepotCard from "./components/DepotCard";
import OrderCard from "./components/OrderCard";
import Navbar from "./components/Navbar";
import OrderCardB2B from "./components/OrderCardB2B";
import Tournee from "./Tournee";

const DriverID = "LOXw3WgAf0XL9D6QdvWba92RNVF2";

// Function to check and request geolocation permission
const checkAndRequestGeolocationPermission = () => {
  return new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
          if (permissionStatus.state === "granted") {
            resolve(true);
          } else if (permissionStatus.state === "prompt") {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                resolve(true);
              },
              (error) => {
                reject(error);
              }
            );
          } else {
            reject("Geolocation permission denied");
          }
        });
    } else {
      reject("Geolocation is not supported");
    }
  });
};

function Home({ selectedDate, setSelectedDate, activeIndex, setActiveIndex }) {
  const [dates, setDates] = useState([]);
  const [locationPermission, setLocationPermission] = useState(false);
  const [tourneeActive, setTourneeActive] = useState([]);
  const [newSelectedDate, setNewSelectedDate] = useState(new Date());

  // Check and request geolocation permission
  useEffect(() => {
    const requestPermission = async () => {
      try {
        await checkAndRequestGeolocationPermission();
        setLocationPermission(true);
      } catch (error) {
        console.error("Error requesting geolocation permission:", error);
        setLocationPermission(false);
      }
    };

    requestPermission();
  }, []);
  // Generate dates array
  useEffect(() => {
    const today = new Date();
    const datesArray = [];
    for (let i = 0; i < 7; i++) {
      const newDate = new Date(today);
      newDate.setDate(today.getDate() + i);
      datesArray.push(newDate);
    }
    setDates(datesArray);
  }, []);

  // Fetch tournee data by selected date & driver id
  const getTourneeData = async () => {
    const newTournees = [];
    const tours = await TestDataService.getTours(new Date(selectedDate));
    const toursByDate = tours.docs.map((doc) => ({
      ...doc.data(),
      idTours: doc.id,
    }));
    if (toursByDate && DriverID) {
      for (const refTour of toursByDate) {
        const docSnapshot = await TestDataService.getTournees(refTour.idTours);
        const tour = docSnapshot.docs.map((doc) => ({
          ...doc.data(),
          idTour: doc.id,
        }));
        for (const tourData of tour) {
          if (tourData.driverId === DriverID) {
            let newTournee = {
              dateTounee: new Date(refTour.tourDate.toDate()),
              points: tourData,
              idTours: refTour.idTours,
              typeTours: refTour.tourType.type,
              departureAndArrivalPoint: refTour.departureAndArrivalPoint.start,
              departureAndArrivalPointEnd: refTour.departureAndArrivalPoint.end,
              evening: refTour.tourType.sousType,
              groupeStatus: refTour.groupeStatus,
            };
            newTournees.push(newTournee);
          }
        }
      }
      setTourneeActive(newTournees);
    } else {
      return setTourneeActive([]);
    }
  };

  const changeSelectedDate = (date) => {
    setSelectedDate(date);
    setNewSelectedDate(date);
  };
  useEffect(() => {
    getTourneeData();
    setNewSelectedDate(selectedDate);
  }, [selectedDate]);
  useEffect(() => {
    getTourneeData();
  }, []);

  useEffect(() => {
    console.log("tourneeActive", tourneeActive);
    console.log("selectedDate", selectedDate);
  }, [tourneeActive, selectedDate]);

  return (
    <div className="p-5 bg-slate-800 h-screen overflow-scroll">
      <Navbar />
      <DateCarousel
        dates={dates}
        setSelectedDate={changeSelectedDate}
        selectedDate={newSelectedDate}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
      {locationPermission ? (
        // Render normal content if location permission is granted
        <>
          {tourneeActive &&
            tourneeActive.map((tourneeObj, index) =>
              tourneeObj.typeTours === "B2C" ? (
                <div key={index}>
                  <div className="h-3 bg-slate-500 my-5"></div>
                  {tourneeObj && (
                    <DepotCard
                      key={tourneeObj.idTours + "start"}
                      tournee={tourneeObj}
                      endDepot={false}
                    />
                  )}
                  <Tournee
                    driversTournee={tourneeObj.points}
                    tournee={tourneeObj}
                  />
                  {tourneeObj && (
                    <DepotCard
                      key={tourneeObj.idTours}
                      tournee={tourneeObj}
                      endDepot={true}
                    />
                  )}
                </div>
              ) : null
            )}
        </>
      ) : null}
    </div>
  );
}

export default Home;
