import { ArrowLeft, Check } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import NavigationButton from "../DepotDetails/components/NavigationButton";
import TestDataService from "../../../services/test.services";
import OrdersDataService from "../../../services/orders.services";
import UsersDataService from "../../../services/users.services";
import DepotArrivalOrderCard from "./DepotArrivalOrderCard";

const DriverID = "12SqhgPLqGaK9mEhsSIX6RDvsZT2";

function DepotDetailsArrival({ selectedDate }) {
  const { state } = useLocation();
  const { depot, shift, end } = state;
  const navigate = useNavigate();
  const [tournee, setTournee] = useState({});
  const [tour, setTour] = useState(null);
  const [pickUpOrders, setPickUpOrders] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [validatedPUOrders, setValidatedPUOrders] = useState(null);
  const [ordersWithDetails, setOrdersWithDetails] = useState([]);

  //Fetch user by id
  const fetchUserById = async (id) => {
    try {
      const userData = await UsersDataService.getUserById(id);
      return userData.userData;
    } catch (error) {
      console.error("Error fetching user data", error);
    }
  };

  // Fetch tournee data by selected date & set tour
  useEffect(() => {
    const fetchTourneeData = async () => {
      try {
        const tourneeData = await TestDataService.getAllTest();
        const parsedTourneeData = tourneeData.docs.map((doc) => doc.data());
        console.log("parsedTourneeData", parsedTourneeData);
        const todayTournee = parsedTourneeData.find((tournee) => {
          const tourDate = tournee.tourDate.toDate();
          const tourneeDay = tourDate.getDate();
          const tourneeMonth = tourDate.getMonth();
          const tourneeYear = tourDate.getFullYear();

          const selectedDay = selectedDate.getDate();
          const selectedMonth = selectedDate.getMonth();
          const selectedYear = selectedDate.getFullYear();
          return (
            tourneeDay === selectedDay &&
            tourneeMonth === selectedMonth &&
            tourneeYear === selectedYear
          );
        });

        if (todayTournee) {
          setTournee(todayTournee);
          const selectedTour = todayTournee.tours.find(
            (tour) => tour.driverId === DriverID && tour.statusTour === 1
          );
          if (selectedTour) {
            setTour(selectedTour);
            console.log("tour", selectedTour);
            setValidatedPUOrders(
              selectedTour.ordersValidation.filter(
                (order) => order.date !== null && order.location !== null
              )
            );
          } else {
            setTour(null);
            setValidatedPUOrders([]);
          }
          setDataFetched(true);
        } else {
          setTournee({});
          setTour(null);
        }
      } catch (error) {
        console.error("Error fetching tournee data:", error);
      } finally {
        tour &&
          tour?.orders &&
          setPickUpOrders(
            tour.orders.filter((order) => order.orderType === "PU")
          );
      }
    };

    fetchTourneeData();
  }, [dataFetched]);

  // Fetch order data for each order ID in dropOffOrders
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const orderData = await OrdersDataService.getAllOrders();
        const parsedOrderssData = orderData.docs.map((doc) => doc.data());
        const orders = parsedOrderssData.filter((order) =>
          pickUpOrders.map((newOrder) => newOrder.id).includes(order.idCommande)
        );

        // Fetch user data for each order
        const usersDataPromises = orders.map((order) =>
          fetchUserById(order.idClient)
        );
        const usersData = await Promise.all(usersDataPromises);

        // Combine order data with user data
        const ordersWithUserData = orders.map((order, index) => ({
          ...order,
          user: usersData[index],
        }));

        setOrdersWithDetails(ordersWithUserData);
      } catch (error) {
        console.error("Error fetching order data", error);
      }
    };

    fetchOrderDetails();
  }, [pickUpOrders]);

  console.log("ordersWithDetails", ordersWithDetails);
  return (
    <div className="flex-col mx-5 mt-5">
      <ArrowLeft
        size={40}
        className="mb-5 cursor-pointer p-1 border-2 border-slate-500 rounded-full"
        color="gray"
        strokeWidth={3}
        onClick={() => navigate(-1)}
      />
      <div className="px-5">
        <div className="text-3xl font-bold my-5">{`${shift.end}-${end}`}</div>
        <div className="h-0.5 w-full bg-slate-500 my-3"></div>
        <div className="flex justify-between items-center my-5">
          <div>
            <div className="text-xl font-bold">{depot.name}</div>
            <div className="text-sm text-slate-500">{depot.address}</div>
          </div>
          <NavigationButton navigationAdress={depot.address} size={40} />
        </div>
        <div className="h-0.5 w-full bg-slate-500 my-3"></div>
      </div>
      <div className="px-5">
        {pickUpOrders && pickUpOrders.length > 0 ? (
          <div className="flex-col">
            {/* Total collectes / scannées */}
            <div>
              <p className="text-md font-bold border-2 border-slate-500 rounded-md p-1 mb-1">
                Total des collectes : ({pickUpOrders.length})
              </p>
              <p className="text-md font-bold border-2 border-slate-500 rounded-md p-1">
                Collectes scannées : ({validatedPUOrders.length})
              </p>
            </div>
            <div className="h-0.5 w-full bg-slate-500 my-3"></div>
            {/* List of pick up orders */}
            <div className="flex-col">
              {ordersWithDetails.map((order, index) => (
                <div
                  key={`${order.id}-${order.idFacture}`}
                  className="my-5"
                >
                  <DepotArrivalOrderCard order={order} />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <Check size={200} color="teal" className="m-auto" />
        )}
      </div>
    </div>
  );
}

export default DepotDetailsArrival;
