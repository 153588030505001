import React, { useEffect, useState } from "react";
import { cn } from "../../../../../@/lib/utils";
import InformClient from "./components/InformClient";
import PressingChoice from "./components/PressingChoice";
import { ShoppingBag } from "lucide-react";

function CollecteTypePage() {
  const [selectedService, setSelectedService] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleChange = (value) => {
    setSelectedService((prevService) => {
      // Clear existing selection if selecting a different service
      if (prevService === value) {
        return null; // Deselect if clicking the same service again
      } else {
        return value; // Select the new service
      }
    });
  };

  useEffect(() => {
    if (selectedService === "lk" || selectedService === "retouche" || selectedService === "cordonnerie") {
      setIsDrawerOpen(true);
    } else {
      setIsDrawerOpen(false);
    }
  }, [selectedService]);

  return (
    <div>
      <div className="flex flex-col gap-y-2 mb-4">
        <div className="flex justify-between">
          <div className="flex flex-col text-lg font-semibold text-slate-400">
            SAC
            <p>99999999</p>
          </div>
          <ShoppingBag size={60} />
        </div>
        <form className="flex flex-col text-lg border-t-2 py-3 px-3 mb-4">
          <div
            className={cn(
              "flex justify-between cursor-pointer py-3 border-b-2",
              selectedService === "lk" && "bg-slate-700"
            )}
            onClick={() => handleChange("lk")}
          >
            <label htmlFor="lk" className="ml-2">
              Linge au kilo
            </label>
            <input
              type="radio"
              name="service"
              id="lk"
              checked={selectedService === "lk"}
              onChange={() => handleChange("lk")}
            />
          </div>
          <div
            className={cn(
              "flex justify-between cursor-pointer py-3 border-b-2",
              selectedService === "pressing" && "bg-slate-700"
            )}
            onClick={() => handleChange("pressing")}
          >
            <label htmlFor="pressing" className="ml-2">
              Pressing
            </label>
            <input
              type="radio"
              name="service"
              id="pressing"
              checked={selectedService === "pressing"}
              onChange={() => handleChange("pressing")}
            />
          </div>
          <div
            className={cn(
              "flex justify-between cursor-pointer py-3 border-b-2",
              selectedService === "retouche" && "bg-slate-700"
            )}
            onClick={() => handleChange("retouche")}
          >
            <label htmlFor="retouche" className="ml-2">
              Retouche
            </label>
            <input
              type="radio"
              name="service"
              id="retouche"
              checked={selectedService === "retouche"}
              onChange={() => handleChange("retouche")}
            />
          </div>
          <div
            className={cn(
              "flex justify-between cursor-pointer py-3 border-b-2",
              selectedService === "cordonnerie" && "bg-slate-700"
            )}
            onClick={() => handleChange("cordonnerie")}
          >
            <label htmlFor="cordonnerie" className="ml-2">
              Cordonnerie
            </label>
            <input
              type="radio"
              name="service"
              id="cordonnerie"
              checked={selectedService === "cordonnerie"}
              onChange={() => handleChange("cordonnerie")}
            />
          </div>
        </form>
        {selectedService === "pressing" && <PressingChoice />}
      </div>
      <InformClient isOpen={isDrawerOpen} onOpenChange={setIsDrawerOpen} />
    </div>
  );
}

export default CollecteTypePage;
