import React from "react";
import RecapCard from "./components/RecapCard";

function Recap() {
  return (
    <div>
      <div className="flex flex-col gap-y-2 mb-4">
        <div className="flex justify-between border-b-2 pb-3 mb-5">
          <div className="flex flex-col text-lg font-semibold text-slate-400">
            CLIENT
            <p className="text-white font-normal">MOUHA LA SQUALE</p>
          </div>
          <div className="flex flex-col text-lg font-semibold text-slate-400">
            COMMANDE
            <p className="text-white font-normal">FR-99-XX-PU</p>
          </div>
        </div>

        <RecapCard sacNumber="99999" type="Linge au kilo" />
        <RecapCard sacNumber="99999" type="Pressing" />
      </div>
    </div>
  );
}

export default Recap;
