import React, { useEffect, useState } from "react";
import OrderCard from "./components/OrderCard";
import OrdersDataService from "../../../services/orders.services";

const DriverID = "LOXw3WgAf0XL9D6QdvWba92RNVF2";

function Tournee({ driversTournee, tournee }) {
  const [driversOrders, setDriversOrders] = useState([]);

  const fetchDriversOrders = async () => {
    try {
      const newOrders = [];
      for (const order of driversTournee?.orders) {
        const orderData = await OrdersDataService.getOrderById(
          order.orderUnder[0].orderId
        );
        newOrders.push(orderData.data());
      }
      setDriversOrders(newOrders);
    } catch (error) {
      console.error("Error fetching driver's orders:", error);
    }
  };

  useEffect(() => {
    fetchDriversOrders();
  }, [driversTournee]);

  console.log("driversOrders", driversOrders);

  return (
    <div>
      {driversTournee &&
        driversTournee?.orders &&
        driversOrders &&
        driversOrders.map((order, index) => (
          <OrderCard
            key={index}
            order={order}
            tour={driversTournee}
          />
        ))}
    </div>
  );
}

export default Tournee;
