import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";

class HubsDataService {
  getAllHubs = () => {
    return getDocs(collection(db, "Hubs"));
  };
  getHubById = (id) => {
    const hubDocRef = doc(db, "Hubs", id);
    return getDoc(hubDocRef);
  };
}
export default new HubsDataService();
